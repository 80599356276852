import dynamic from "next/dynamic";

import type { Props as HeaderProps } from "~/components/header";
import type { Props as HomeHeaderProps } from "~/components/home/header";
import type { Props as HomeMiniHeaderProps } from "~/components/home/mini-header";
import type { Props as PlusHeaderProps } from "~/components/plus/header";
import type { Props as PostiesBrochureHeaderProps } from "~/components/posties/header/posties-brochure-header";
import type { Props as PostiesContentHeaderProps } from "~/components/posties/header/posties-content-header";

export const DynamicStyleContentHeader = dynamic<HeaderProps>(() =>
  import("~/components/section/section-style/section-style-header").then(
    module => module.StyleContentHeader,
  ),
);

export const DynamicPostiesBrochureHeader = dynamic<PostiesBrochureHeaderProps>(() =>
  import("~/components/posties/header/posties-brochure-header").then(
    module => module.PostiesBrochureHeader,
  ),
);

export const DynamicPostiesContentHeader = dynamic<PostiesContentHeaderProps>(() =>
  import("~/components/posties/header/posties-content-header").then(
    module => module.PostiesContentHeader,
  ),
);

export const DynamicHeader = dynamic<HeaderProps>(() =>
  import("~/components/header").then(module => module.Header),
);

export const DynamicHomeHeader = dynamic<HomeHeaderProps>(() =>
  import("~/components/home/header").then(module => module.HomeHeader),
);

export const DynamicHomeMiniHeader = dynamic<HomeMiniHeaderProps>(() =>
  import("~/components/home/mini-header").then(module => module.MiniHeader),
);

export const DynamicPlusHeader = dynamic<PlusHeaderProps>(() =>
  import("~/components/plus/header").then(module => module.PlusHeader),
);
