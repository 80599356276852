import { NoSsr } from "@mui/base";
import { useTheme } from "@mui/material/styles";
import { theme, useResponsive } from "@product/scmp-sdk";
import { usePrevious } from "@react-hookz/web";
import { useScroll } from "ahooks";
import merge from "deepmerge";
import { useAtomValue } from "jotai";
import type { PageProps } from "next";
import type { CSSProperties, FunctionComponent, ReactNode } from "react";
import { useMemo } from "react";

import { DynamicAdSlot } from "~/components/advertisement/ad-slots/ad-slot/dynamics";
import { appBarScrollStickyAtom } from "~/components/app-bar/hooks/sticky/atoms";
import { HeaderBottomContainerElementID } from "~/pages/_app/consts";

import { appBarAdSlotAtom, appBarAtom } from "./atoms";
import { HideElementOnScrollEffectThreshold } from "./consts";
import { AppBarContextProvider } from "./contexts";
import type { AppBarVariant } from "./helpers";
import { useAppBarSetStickyHeight } from "./hooks";
import {
  AdSlotContainer,
  Container,
  FullWidthBackground,
  HeaderBottomContainer,
  StyledHeader,
  StyledHomeHeader,
  StyledHomeMiniHeader,
  StyledPlusHeader,
  StyledPostiesBrochureHeader,
  StyledPostiesContentHeader,
  StyledStyleContentHeader,
} from "./styles";
import type { AppBarConfiguration } from "./types";

export type Props = {
  children?: ReactNode;
  className?: string;
  hideTargetChildren?: ReactNode;
  pageProps?: PageProps;
};

export const AppBar: FunctionComponent<Props> = ({ children, className, pageProps }) => {
  const { adSlotHeight, bindAdSlotContainer, responsiveAdSlot, stickyStyle } = useResponsiveAdSlot(
    pageProps?.appBarConfiguration,
  );

  const appBarValue = useAtomValue(appBarAtom);
  const { stickyScrollHeight = 0 } = useAtomValue(appBarScrollStickyAtom);
  const appBarStickyScrollHeight = useMemo(
    // added 2 trying to avoid double menu when scrolling up/down, should find a better way.
    () => stickyScrollHeight + adSlotHeight,
    [adSlotHeight, stickyScrollHeight],
  );

  const appBarVariant = useMemo<AppBarVariant>(
    () =>
      appBarValue?.scrollOverVariant ??
      pageProps?.appBarConfiguration?.variant ??
      "scmp/generic-light",
    [appBarValue?.scrollOverVariant, pageProps?.appBarConfiguration?.variant],
  );

  const hideElementOnScrollEffect = useStickyWithHideElementAfterScrollThreshold(
    HideElementOnScrollEffectThreshold.mobile,
    appBarStickyScrollHeight,
  );
  const stickyWithScrollAwayElement = useStickyWithScrollAwayElement(appBarStickyScrollHeight);

  const styles: CSSProperties = {
    ...(stickyStyle === "withHideElementAfterScrollThreshold"
      ? hideElementOnScrollEffect.targetContainerStyles
      : stickyWithScrollAwayElement.targetContainerStyles),
  };

  const { reference } = useAppBarSetStickyHeight();

  const homeHeaderConfiguration = {
    ...pageProps?.headerConfiguration,
    appBarStickyScrollHeight,
    appBarVariant,
  };

  const renderHeader = () => {
    switch (appBarVariant) {
      case "posties/generic":
        return <StyledPostiesContentHeader />;
      case "posties/brochure":
        return <StyledPostiesBrochureHeader />;
      case "scmp/magazines-style":
        const headerConfiguration = {
          ...pageProps?.headerConfiguration,
          appBarStickyScrollHeight,
          appBarVariant,
        };
        return <StyledStyleContentHeader headerConfiguration={headerConfiguration} />;
      case "scmp/home":
        return <StyledHomeHeader headerConfiguration={homeHeaderConfiguration} />;
      case "scmp/home-slim":
        return <StyledHomeMiniHeader headerConfiguration={homeHeaderConfiguration} />;
      case "scmp/plus":
        return <StyledPlusHeader />;
      default:
        return <StyledHeader headerConfiguration={pageProps?.headerConfiguration} />;
    }
  };

  return (
    <AppBarContextProvider>
      <Container
        $appBarVariant={appBarVariant}
        className={className}
        ref={reference}
        style={{ ...styles }}
      >
        <FullWidthBackground />
        <AdSlotContainer {...bindAdSlotContainer}>
          <NoSsr>{responsiveAdSlot}</NoSsr>
        </AdSlotContainer>
        {children}
        {renderHeader()}
        <HeaderBottomContainer id={HeaderBottomContainerElementID} />
      </Container>
    </AppBarContextProvider>
  );
};

AppBar.displayName = "AppBar";

const defaultAdSlotVariants = {
  large: {
    height: 250,
    padding: 20,
  },
  small: {
    height: 100,
    padding: 10,
  },
} as const;

const styleAdSlotVariants = merge(defaultAdSlotVariants, {
  small: {
    padding: 20,
  },
});

const useResponsiveAdSlot = (configuration?: AppBarConfiguration) => {
  const adSlotValue = useAtomValue(appBarAdSlotAtom);

  const adSlotVariants = {
    ...(configuration?.variant === "scmp/magazines-style"
      ? styleAdSlotVariants
      : defaultAdSlotVariants),
    ...configuration?.adSlotVariants,
  };

  const bindAdSlotContainer = useMemo(() => {
    const largeStyles = {
      $height: adSlotVariants.large.height,
      $isVisible: configuration?.hasDesktopAd,
      $padding: adSlotVariants.large.padding,
    };
    const smallStyles = {
      $height: adSlotVariants.small.height,
      $isVisible: configuration?.hasMobileAd,
      $padding: adSlotVariants.small.padding,
    };

    switch (configuration?.variant) {
      case "scmp/home":
        return {
          $responsiveVariants: {
            desktopUp: smallStyles,
            homeDesktopUp: largeStyles,
            mobileUp: smallStyles,
            tabletUp: smallStyles,
          },
        };
      default:
        return {
          $responsiveVariants: {
            desktopUp: largeStyles,
            mobileUp: smallStyles,
            tabletUp: smallStyles,
          },
        };
    }
  }, [
    adSlotVariants.large.height,
    adSlotVariants.large.padding,
    adSlotVariants.small.height,
    adSlotVariants.small.padding,
    configuration?.hasDesktopAd,
    configuration?.hasMobileAd,
    configuration?.variant,
  ]);

  const isDesktopUp = useResponsive(
    theme.breakpoints.up(configuration?.desktopBreakpoint ?? "desktop"),
  );
  const { adSlotHeight, responsiveAdSlot, stickyStyle } = useMemo(() => {
    switch (true) {
      case isDesktopUp:
        return {
          adSlotHeight: adSlotValue?.desktop
            ? adSlotVariants.large.height + adSlotVariants.large.padding * 2
            : 0,
          responsiveAdSlot: adSlotValue?.desktop ? (
            <DynamicAdSlot
              sizes={[
                [970, 250],
                [728, 90],
                [970, 90],
              ]}
              {...adSlotValue.desktop}
            />
          ) : null,
          stickyStyle: "withScrollAwayElement" as const,
        };
      default:
        return {
          adSlotHeight: adSlotValue?.mobile
            ? adSlotVariants.small.height + adSlotVariants.small.padding * 2
            : 0,
          responsiveAdSlot: adSlotValue?.mobile ? (
            <DynamicAdSlot
              sizes={[
                [300, 100],
                [320, 100],
                [300, 50],
                [320, 50],
              ]}
              {...adSlotValue.mobile}
            />
          ) : null,
          stickyStyle: "withHideElementAfterScrollThreshold" as const,
        };
    }
  }, [
    adSlotValue?.desktop,
    adSlotValue?.mobile,
    adSlotVariants.large.height,
    adSlotVariants.large.padding,
    adSlotVariants.small.height,
    adSlotVariants.small.padding,
    isDesktopUp,
  ]);

  return {
    adSlotHeight,
    bindAdSlotContainer,
    responsiveAdSlot,
    stickyStyle,
  };
};

export const useShouldHidden = (scrollThreshold: number) => {
  const position = useScroll();
  const containerElementScrollOffsetY = position?.top ?? 0;
  return containerElementScrollOffsetY > scrollThreshold;
};

// This effect will hide the element with transition after the scroll container scroll passes a threshold
const useStickyWithHideElementAfterScrollThreshold = (
  scrollThreshold: number,
  targetElementHeight: number,
) => {
  const theme = useTheme();
  const previousTargetElementHeight = usePrevious(targetElementHeight);

  const shouldHidden = useShouldHidden(scrollThreshold);
  const shouldPauseTransition = previousTargetElementHeight !== targetElementHeight;

  const targetContainerStyles: CSSProperties = {
    position: "sticky",
    transform: shouldHidden ? `translateY(-${targetElementHeight}px)` : "none",
    transition: shouldPauseTransition
      ? "none" // eslint-disable-next-line etc/no-internal
      : theme.transitions.create("transform", {
          duration: 50,
        }),
  };

  return {
    targetContainerStyles,
  };
};

// This effect will make the container sticky after the scroll away element is complete scroll
// passed in the scroll container
const useStickyWithScrollAwayElement = (scrollAwayElementHeight: number) => {
  const position = useScroll();
  const containerElementScrollOffsetY = position?.top ?? 0;

  const shouldStick =
    containerElementScrollOffsetY > 0 && containerElementScrollOffsetY >= scrollAwayElementHeight;

  const targetContainerStyles: CSSProperties = {
    position: shouldStick ? "sticky" : "relative",
    transform: shouldStick ? `translateY(-${scrollAwayElementHeight}px)` : "none",
  };

  return {
    targetContainerStyles,
  };
};
